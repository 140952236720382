import { gql } from '@apollo/client';


export const GET_PROJECTS = gql`
  query GetProjects {
    projects(page: 1, size: 20) {
      list {
        id
        name
        children_count
      }
    }
  }
`;
