import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// API 
const hostname = window && window.location && window.location.hostname
let httpLink = null
if(/weldingo\.com$/.test(hostname)) {
  httpLink = new HttpLink({
    uri: `https://app.${/([a-zA-Z-]+).weldingo.com/.exec(hostname)[1]}.weldingo.com/graphql`
  });
} else {
  httpLink = new HttpLink({
    uri: `${process.env[`REACT_APP_API_BASE_URL`]}/graphql`
  });
}

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('auth_token');

  if (!token) {
    return {
      headers: {
        ...headers,
      },
    };
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
