import { gql } from '@apollo/client';

export const SIGN_IN_MUTATION = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(email: { email: $email, password: $password }) {
      token
      refresh_token
      errors
      user {
        email
        id
        last_sign_in_at
      }
    }
  }
`;
