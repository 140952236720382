import { useMutation } from '@apollo/client';
import { Alert, Box, Button, Container, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SIGN_IN_MUTATION } from '../graphql/signInMutation';
import weldingo from '../images/weldingo.png';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate(); 

  const [signInUser, { loading }] = useMutation(SIGN_IN_MUTATION, {
    onCompleted: (data) => {

      const { signInUser } = data;


      if (signInUser.errors && signInUser.errors.length > 0) {
        setErrorMessage(signInUser.errors.join(', '));
        return;
      }

      if (signInUser.token) {
        setSuccessMessage('Login successful. You are now logged in.');
        localStorage.setItem('auth_token', signInUser.token);
        localStorage.setItem('refresh_token', signInUser.refresh_token);
        localStorage.setItem('user_id', signInUser.user.id); 
        localStorage.setItem('email', email)
        navigate('/project-list');
      } else {
        setErrorMessage('Login failed. Incorrect credentials, please try again.');
      }
    },
    onError: (err) => {
      setErrorMessage('An error occurred during login. Please try again.');
      console.error(err);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');  
    setSuccessMessage(''); 
    signInUser({
      variables: { email, password },
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4,
          marginTop: 8,
          boxShadow: 3,
          borderRadius: 2,
          paddingTop: 3,
          backgroundColor: '#fff',
        }}
      >
         <img
          src={weldingo}
          alt="Weldingo"
          style={{ height: '100px' }}
        />
        <Typography variant="h5" gutterBottom>
          Zaloguj się
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            label="Email"
            variant="outlined"
            sx={{ height:"70px"}}
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Hasło"
            type="password"
            variant="outlined"
            sx={{ height:"70px"}}
            fullWidth
            required
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 4 , height:"70px"}}
            disabled={loading}
          >
            Zaloguj się
          </Button>
        </form>
        {successMessage && (
          <Alert severity="success" sx={{ mt: 4 }}>
            {successMessage}
          </Alert>
        )}

        {errorMessage && (
          <Alert severity="error" sx={{ mt: 4 }}>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default Login;
