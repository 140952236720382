import React from 'react';
import Menu from "../components/Menu";
import ProjectList from "../components/ProjectList";

const ProjectListPage = () => {
  return (
    <>
    <Menu />
    <ProjectList />
    </>
  );
}

export default ProjectListPage;
