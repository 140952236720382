import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import ProjectListPage from "./pages/ProjectListPage";
import PrivateRoute from './utils/PrivateRoute';


function App() {
  const isAuthenticated = localStorage.getItem('auth_token') !== null;

  return (
    <Router>
      <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/project-list" /> : <LoginPage />} />
      <Route path="/project-list" element={<PrivateRoute><ProjectListPage /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
