import { ApolloProvider } from '@apollo/client'; // Import Apollo Client
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'; // Importujemy MUI dla globalnych stylów i motywu
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import client from './appoloClient';

const theme = createTheme({
  palette: {
    mode: 'light', 
    primary: {
      main: '#1976d2', 
    },
    secondary: {
      main: '#9c27b0', 
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', 
    fontSize: 20,
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);
